import styled from "styled-components";

export const ContainerArea=styled.div`
 width:100%;
 height:100%;
 display:flex;
 flex-direction:row;
 padding-bottom:50px;

`;


export const LeftMarginArea=styled.div`
 flex:auto;

`;

export const RightMarginArea=styled.div`
 flex:auto;
`;

export const ContextArea=styled.div`
width:1280px;
   flex-direction:column;
`;


export const SearchArea=styled.div`




`;

export const FootArea=styled.div`
 text-align: center;
 margin-top:20px;
 padding:10px;
  font-size: 14px;
`;


export const SiteArea=styled.div`
 margin-top:20px;
 box-shadow: 0px 1px 2px #888888;
 padding:20px


`;

export const UtilArea=styled.div`
 margin-top:20px;
 box-shadow: 0px 1px 2px #888888;
 padding:20px

`;



export const LogoArea=styled.div`
text-align: center;
margin-top:20px;

`;


export const SearchContainer=styled.div`

`;

export const SearchForm=styled.form`
width: 100%;
height: 100px;
padding: 20px 20px 20px;
text-align: center;
border: 1px solid #aaaaaa;
input[type="submit"]{
 color: ${props => { 
      if(props.searchName=="Baidu"){
          return "#4285F4"
      }else if(props.searchName=="Google"){
          return "#EA4335" 
      }  if(props.searchName=="Bing"){
        return "#4285F4"
        }else if(props.searchName=="360"){
         return "#34A853"
        } else {
         return "#EF18E7"
         }
 }
}
};

input[type="submit"]{
 border: ${props => {
    if(props.searchName=="Baidu"){
        return "1px solid #4285F4"
    }else if(props.searchName=="Google"){
        return "1px solid #EA4335"
    }  if(props.searchName=="Bing"){
        return "1px solid #4285F4"
    }else if(props.searchName=="360"){
        return "1px solid #34A853"
    } else {
        return "1px solid #EF18E7"
    }
}
    }
}



`;

export  const SearchInput=styled.input`
  width: 600px;
  height: 44px;
  border:none;
  outline:none;
  border-radius: 10px 10px 10px 10px;
`;


export  const SearchSubmit=styled.input`
     cursor: pointer;
     width: 108px;
     height: 44px;
     line-height: 44px;
     padding: 0;
     margin-left:20px;
     background: 0 0;
     border-radius: 10px 10px 10px 10px;
     font-size: 17px;
     box-shadow: none;
     font-weight: 400;
     border: solid 1px #4F4F4F;
     outline: 0;
`;

export const LogoImg=styled.img`
 height:60px;

`;

export const InputContainer=styled.div`
 display:inline-block;
  position:relative;
  height:48px;
  border-radius: 10px 10px 10px 10px;
  border: solid 1px #4F4F4F;
 img{
  position:relative;
  top:8px;
  visibility:hidden;
  z-index :100;
 }
`;

export const UrlItem=styled.a`
 margin:20px;
 font-size: 16px;

`;

export const SiteTitle=styled.div`
 display:inline-block;
 width:80px;

`;

export const SiteContent=styled.div`
 display:inline-block;

`;

export const SiteGroup=styled.div`
 padding:5px;
 font-size: 16px;
 display:flex;
 flex-direction:row;

`;